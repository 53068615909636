<template lang="pug">
	article#cardFuncionario
		.logo
			h1
				|GT. Company - Negócios Inteligentes
				img(:src="require('@/assets/images/logo/logo_gtcompany.png')", alt="GT. Company - Negócios Inteligentes")
		.funcionario
			.imgFuncionario
				.img
					img(:src="conteudo.media")
			.infoFuncionario
				h2 {{conteudo.nome}}
				h3 {{conteudo.cargo}}
		ul.contato
			li(v-if="conteudo.email")
				a(target="_blank" :href="`mailto:${conteudo.email}`")
					img(:src="require('@/assets/images/icons/card/email.jpg')")
			li(v-if="conteudo.whats")
				a(target="_blank" :href="`https://wa.me/55${conteudo.whats}`")
					img(:src="require('@/assets/images/icons/card/whats.jpg')")
			li(v-if="conteudo.site")
				a(target="_blank" :href='conteudo.site')
					img(:src="require('@/assets/images/icons/card/site.jpg')")
			li(v-if="conteudo.endereco")
				a(target="_blank" :href="'https://www.google.com.br/maps/search/'+encodeURI(conteudo.endereco)")
					img(:src="require('@/assets/images/icons/card/pin.jpg')")
		.redes
			a(target="_blank" :href='conteudo.linkedin')
				img(:src="require('@/assets/images/icons/card/linkedin.jpg')")
			p 
				span Redes Sociais
		.ajuda
			img(:src="require('@/assets/images/icons/card/interacao.jpg')")
			p 
				span Clique nos ícones para interagir
</template>

<script>
export default {
	name: 'view-cardFuncionario',
	data() {
		return {
			conteudo: [],
		}
	},
	created() {
		this.loadFuncionario(this.$route.params.slug)
	},
	methods: {
		loadFuncionario(slug = '') {
            this.$axios
                .get(`funcionarios/${slug}`)
                .then(response => this.conteudo = response.data.registros)
		} 

	},
}
</script>

<style lang="stylus" scoped src="./CardFuncionario.styl"></style>
